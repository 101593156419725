import { NgModule } from '@angular/core';
import { TimeAgoPipe } from './time-ago/time-ago';
import { DayOfWeekPipe } from './day-of-week/day-of-week';
import { TimePipe } from './time/time';
import { TicketTypePipe } from './ticket-type/ticket-type';
import { ToDateObjPipe } from './to-date-obj/to-date-obj.pipe';
import { SubscriptionTypePipe } from './subscription-type/subscription-type';
import { TruncatePipe } from './truncate/truncate';
import { CallbackPipe } from './callback/callback.pipe';
import { ToDateStringPipe } from './to-date-string/to-date-string.pipe';
import { TypeofPipe } from './typeof/typeof';
@NgModule({
  declarations: [
    TimeAgoPipe,
    DayOfWeekPipe,
    TimePipe,
    TicketTypePipe,
    ToDateObjPipe,
    ToDateStringPipe,
    SubscriptionTypePipe,
    TruncatePipe,
    CallbackPipe,
    TypeofPipe,
  ],
  imports: [],
  exports: [
    TimeAgoPipe,
    DayOfWeekPipe,
    TimePipe,
    TicketTypePipe,
    ToDateObjPipe,
    ToDateStringPipe,
    SubscriptionTypePipe,
    TruncatePipe,
    CallbackPipe,
    TypeofPipe,
  ],
})
export class PipesModule {}
