import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  currentLoading = null;

  constructor(public loadingController: LoadingController) {}

  async present(message: string = 'Cargando...', duration: number = null) {
    // Dismiss previously created loading
    if (this.currentLoading != null) {
      this.currentLoading.dismiss();
    }

    this.currentLoading = await this.loadingController.create({
      duration,
      message,
    });

    return await this.currentLoading.present();
  }

  async dismiss(): Promise<void> {
    await this.dismissAllLoaders();
  }

  async dismissAllLoaders() {
    try {
      let topLoader = await this.loadingController.getTop();
      while (topLoader) {
        if (!(await topLoader.dismiss())) {
          // throw new Error('Could not dismiss the topmost loader. Aborting...');
          break;
        }
        topLoader = await this.loadingController.getTop();
      }
    } catch (error) {
      console.log('dismissAllLoaders error: ', error);
    }
  }
}
