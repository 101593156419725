import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { environment } from '@environments/environment';
import { getParsedValue } from '@shared/utils';

import { EncryptionService } from '../encryption/encryption.service';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  storagePrefix: string = environment.storagePrefix || '';
  encrypted: boolean = environment.storageEncryption || false;

  constructor(private encryptionService: EncryptionService) {}

  async set<T>(key: string, value: T): Promise<void> {
    const stringValue = JSON.stringify(value);
    const storedValue = this.encrypted ? this.encryptionService.encrypt(stringValue) : stringValue;
    await Preferences.set({ key: this.storagePrefix + key, value: storedValue });
  }

  async get<T>(key: string): Promise<T | null> {
    const result = await Preferences.get({ key: this.storagePrefix + key });
    if (!result.value) {
      return null;
    }
    if (!this.encrypted) {
      return getParsedValue(result.value);
    }
    const decryptedValue = this.encryptionService.decrypt(result.value);
    return getParsedValue(decryptedValue as string);
  }

  async clear(): Promise<void> {
    await Preferences.clear();
  }

  async remove(key: string): Promise<void> {
    await Preferences.remove({ key: this.storagePrefix + key });
  }
}
