import { BehaviorSubject, defer, merge, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { User } from '@shared/models';

import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userSubject = new BehaviorSubject<User | null>(null);
  user$: Observable<User | null> = merge(
    this.userSubject.asObservable(),
    defer(() => this.getUserFromLocalStorage())
  ).pipe(shareReplay(1));

  constructor(private storage: StorageService) {}

  async getUserFromLocalStorage(): Promise<User | null> {
    return await this.storage.get<User>('me');
  }

  async updateUser(user: Partial<User>): Promise<void> {
    const currentUser = this.userSubject.getValue();
    const updatedUser = { ...currentUser, ...user } as User;
    await this.storage.set<User>('me', updatedUser);
    this.userSubject.next(updatedUser);
  }

  async removeFromLocalStorage(): Promise<void> {
    await this.storage.remove('me');
    this.userSubject.next(null);
  }
}
