import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-sale',
  templateUrl: './sale.component.html',
  styleUrls: ['./sale.component.scss'],
})
export class SaleComponent implements OnInit {
  @Input() meal_plan_name: string; // = "Desayuno doble";
  @Input() user: any; // = "users/5ebd19ec59aa4c1141c1e434d4494e1b.jpg";

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {}

  async close() {
    this.modalCtrl.dismiss();
  }
}
