import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ReviewComponent } from './review.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        component: ReviewComponent,
      },
    ]),
    CommonModule,
  ],
  declarations: [ReviewComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ReviewModule {}
