import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ApiResponse, SchoolPeriodSlot, Suscription } from '@shared/models';

import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class SuscriptionService {
  private updateSuscriptionSubject = new BehaviorSubject<void>(null);
  suscription$: Observable<Suscription> = this.updateSuscriptionSubject.asObservable().pipe(
    switchMap(() => this.getSuscription()),
    shareReplay(1)
  );
  private suscriptionSubject = new BehaviorSubject<Suscription>(null);

  constructor(private apiService: ApiService) {}

  getSuscription(): Observable<Suscription> {
    return this.apiService.get<ApiResponse<Suscription>>('suscription').pipe(
      map((response) => response.data),
      tap((suscription) => this.suscriptionSubject.next(suscription)),
      catchError(() => EMPTY)
    );
  }

  isExtraRegistrationOpen() {
    const suscription = this.suscriptionSubject.getValue();
    if (
      !suscription ||
      suscription.name.toLowerCase() === 'pv' ||
      suscription.status !== 0 ||
      !suscription.school_period ||
      !suscription.school_period.extra_registration_start_date ||
      !suscription.school_period.extra_registration_end_date
    ) {
      return false;
    }

    const today = new Date();
    const start = new Date(suscription.school_period.extra_registration_start_date);
    const end = new Date(suscription.school_period.extra_registration_end_date);
    if (today >= start && today <= end) {
      return true;
    } else {
      return false;
    }
  }

  isResetSuscribable(): boolean {
    const suscription = this.suscriptionSubject.getValue();
    if (
      !suscription ||
      suscription.status !== 0 ||
      suscription.name.toLowerCase() === 'pv' ||
      suscription?.suscription_dailies?.length > 0
    ) {
      return false;
    }
    return true;
  }

  updateSuscription() {
    this.updateSuscriptionSubject.next();
  }

  getSuscriptions(): Observable<Suscription[]> {
    return this.apiService.get<ApiResponse<Suscription[]>>('suscriptions').pipe(map((response) => response.data));
  }

  getSchoolPeriodSlots(): Observable<SchoolPeriodSlot[]> {
    return this.apiService
      .get<ApiResponse<SchoolPeriodSlot[]>>('school_period_slots')
      .pipe(map((response) => response.data));
  }
}
