<ion-header class="ion-no-border" [hidden]="!has_toolbar">
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-button (click)="close()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ title }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding" [class]="bg_class">
  <ion-button (click)="close()" class="close" fill="clear" color="light" [hidden]="has_toolbar">
    <ion-icon slot="icon-only" name="close"></ion-icon>
  </ion-button>
  <div [innerHTML]="content"></div>
</ion-content>
