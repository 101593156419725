import { Injectable } from '@angular/core';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class GuardiaGuard {
  constructor(public auth: AuthService) {}

  canActivate(): Promise<boolean> {
    return this.auth.isGuard().then((authenticated) => {
      if (authenticated) {
        return true;
      } else {
        return false;
      }
    });
  }
}
