import { Component, OnInit, Optional } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { IonRouterOutlet, NavController, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-id',
  templateUrl: './id.page.html',
  styleUrls: ['./id.page.scss'],
})
export class IdPage implements OnInit {
  user: any;

  constructor(
    private auth: AuthService,
    @Optional() private routerOutlet: IonRouterOutlet,
    private modalCtrl: ModalController,
    private navCtrl: NavController
  ) {}

  ngOnInit() {
    this.auth.getUser().then((user) => {
      this.user = user;
    });
  }

  close() {
    setTimeout(async () => {
      const isModalOpened = await this.modalCtrl.getTop();
      if (isModalOpened) {
        this.modalCtrl.dismiss();
      } else if (this.routerOutlet && this.routerOutlet.canGoBack()) {
        this.routerOutlet.pop();
      } else {
        this.navCtrl.navigateBack('/tecservice/home');
      }
    }, 300);
  }
}
