import * as CryptoJS from 'crypto-js';

import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  privateKey = environment.cryptoKey;

  encrypt(value: string, key: string = this.privateKey): string {
    if (!value) {
      return '';
    }
    return CryptoJS.AES.encrypt(value, key).toString();
  }

  decrypt(value: string, key: string = this.privateKey): string {
    if (!value) {
      return '';
    }
    return CryptoJS.AES.decrypt(value, key).toString(CryptoJS.enc.Utf8);
  }
}
