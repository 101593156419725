<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-title>VALORA</ion-title>
    <ion-buttons slot="start">
      <ion-button color="primary" (click)="close()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
  <img src="assets/icon/Like-01.png" />
  <h5 [hidden]="!businessId">Califica el establecimiento</h5>
  <h5 [hidden]="endpoint !== 'transportecrating'">Califica el servicio Transporte</h5>
  <h5 [hidden]="endpoint !== 'expresorating'">Califica el servicio ExpresoTec</h5>
  <h5 [hidden]="endpoint !== 'mealplanrating'">Califica el mealplan</h5>
  <br />
  <p>Toca las estrellas para calificar</p>

  <ion-grid>
    <ion-row *ngFor="let item of scores; let pos = index">
      <ion-col size="12" class="ion-text-center">
        <p class="p-title" style="color: #000000; font-weight: normal; margin-top: 20px">{{ item.name }}</p>
        <p class="p-mega" style="color: #000000; font-weight: normal; margin-top: 10px">
          <ion-icon
            [src]="item.score >= 1 ? '/assets/icon/star-check.svg' : '/assets/icon/star.svg'"
            (click)="setScore(1, pos)"
            style="cursor: pointer"
            [ngStyle]="{ 'color': item.score >= 1 ? '#c3d62f' : '#000000' }"
          ></ion-icon>
          &nbsp;&nbsp;
          <ion-icon
            [src]="item.score >= 2 ? '/assets/icon/star-check.svg' : '/assets/icon/star.svg'"
            (click)="setScore(2, pos)"
            style="cursor: pointer"
            [ngStyle]="{ 'color': item.score >= 2 ? '#c3d62f' : '#000000' }"
          ></ion-icon>
          &nbsp;&nbsp;
          <ion-icon
            [src]="item.score >= 3 ? '/assets/icon/star-check.svg' : '/assets/icon/star.svg'"
            (click)="setScore(3, pos)"
            style="cursor: pointer"
            [ngStyle]="{ 'color': item.score >= 3 ? '#c3d62f' : '#000000' }"
          ></ion-icon>
          &nbsp;&nbsp;
          <ion-icon
            [src]="item.score >= 4 ? '/assets/icon/star-check.svg' : '/assets/icon/star.svg'"
            (click)="setScore(4, pos)"
            style="cursor: pointer"
            [ngStyle]="{ 'color': item.score >= 4 ? '#c3d62f' : '#000000' }"
          ></ion-icon>
          &nbsp;&nbsp;
          <ion-icon
            [src]="item.score >= 5 ? '/assets/icon/star-check.svg' : '/assets/icon/star.svg'"
            (click)="setScore(5, pos)"
            style="cursor: pointer"
            [ngStyle]="{ 'color': item.score >= 5 ? '#c3d62f' : '#000000' }"
          ></ion-icon>
        </p>
      </ion-col>
    </ion-row>
  </ion-grid>
  <br />
  <ion-list>
    <ion-item>
      <ion-label color="primary" position="floating" #comentarios>Comentarios:</ion-label>
      <ion-textarea
        [(ngModel)]="comments"
        [value]="comments"
        name="comments"
        (keyup)="updateComments($event)"
        #commentsTextArea
      ></ion-textarea>
    </ion-item>
  </ion-list>
  <div class="row">
    <div class="col text-center">
      <ion-button
        shape="round"
        fill="outline"
        color="primary"
        [disabled]="!comments || comments == ''"
        (click)="sendToServer()"
      >
        Enviar
      </ion-button>
    </div>
  </div>
  <div class="row" [hidden]="!businessId">
    <div class="col text-center">
      <ion-button shape="round" fill="outline" color="primary" (click)="goHelp()">
        <ion-label>Ayuda</ion-label>
        <ion-icon slot="start" src="assets/icon/help.svg"></ion-icon>
      </ion-button>
    </div>
  </div>
</ion-content>
