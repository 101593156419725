import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { EventService } from '../services/event.service';
import { NotificationService } from '../services/notification/notification.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private eventService: EventService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        console.log('TCL: ErrorInterceptorService -> error', error);
        let errorMessage = '';
        if (error.status === 0) {
          console.log('no internet connection');
        } else if (error.error.hasOwnProperty('errors') && error.error.errors.length) {
          error.error.errors.forEach((errorMsg) => {
            this.printMessage(errorMsg);
          });
        } else if (Array.isArray(error.error.message)) {
          errorMessage += error.error.message[0];
          this.printMessage(errorMessage);
        } else {
          if (error.status === 408 || error.status === 504) {
            this.printMessage('Solicitud demorada, intente nuevamente.');
          } else {
            errorMessage += error.message;
            this.printMessage(error.name + ' - ' + errorMessage);
          }
        }
        if (error.status === 401) {
          this.eventService.publish('user:logout');
        }
        return throwError(error);
      })
    );
  }

  printMessage(errorMsg: string) {
    if (!environment.production) {
      this.notificationService.presentToast(errorMsg);
    }
  }
}
