<ion-content class="scanningBg ion-padding">
  <ion-button (click)="close()" class="close" fill="clear" color="light">
    <ion-icon slot="icon-only" name="close"></ion-icon>
  </ion-button>
  <div id="container">
    <div class="photo" [style.backgroundImage]="'url(' + user.avatar + ')'" offset="50"></div>
    <h1>{{ meal_plan_name }}</h1>
    <h3>{{ user.gender }}</h3>
  </div>
</ion-content>
