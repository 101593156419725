<ion-header class="ion-no-border">
  <ion-toolbar color="dark">
    <ion-buttons slot="start">
      <ion-button (click)="closeModal()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ imgTitle }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [forceOverscroll]="false" color="dark" fullscreen="true">
  <swiper-container>
    <swiper-slide>
      <div class="swiper-zoom-container"><img [src]="imgSource" height="100vh" /></div>
    </swiper-slide>
    <swiper-slide>
      <div class="swiper-zoom-container"><img [src]="imgSource" height="100vh" /></div>
    </swiper-slide>
  </swiper-container>
</ion-content>

<ion-footer>
  <ion-toolbar color="dark" class="ion-text-center">
    <ion-text>{{ imgDescription }}</ion-text>
  </ion-toolbar>
</ion-footer>
