import { differenceInYears } from 'date-fns';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { LoadingService } from 'src/app/services/loading.service';
import { NotificationService } from 'src/app/services/notification/notification.service';

import { Component, inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '@core/services';
import { ModalController } from '@ionic/angular';
import { Campus, User } from '@shared/models';
import { Supplier } from '@shared/models/supplier.model';

import { ApiResponse } from '../../../shared/models/api.model';

@Component({
  selector: 'app-register-saml-modal',
  templateUrl: './register-saml-modal.component.html',
  styleUrls: ['./register-saml-modal.component.scss'],
})
export class RegisterSamlModalComponent implements OnInit {
  #apiService: ApiService = inject(ApiService);
  #modalCtrl: ModalController = inject(ModalController);
  #loadingService: LoadingService = inject(LoadingService);
  #notificationService: NotificationService = inject(NotificationService);
  #fb: FormBuilder = inject(FormBuilder);

  @Input() user: Partial<User> | null;
  @Input() mode: 'register' | 'update' | 'read' = 'register';

  form: FormGroup = this.#fb.group({
    campus: new FormControl<number>(null, { validators: Validators.required }),
    underAge: new FormControl<boolean>(false, { validators: Validators.requiredTrue }),
  });
  showUnderAgeFormControl = true;
  campus$: Observable<Campus[]> = this.#getCampus();
  supplier$: Observable<Supplier> = this.form.get('campus').valueChanges.pipe(
    filter((campusId) => !!campusId),
    tap(() => this.#loadingService.present()),
    switchMap((campusId) => this.#getSupplier(campusId)),
    tap(() => this.#loadingService.dismiss())
  );

  ngOnInit(): void {
    setTimeout(() => {
      this.form.get('campus').setValue(this.user?.campus_current || null, { emitEvent: true });
      if (this.user?.birthday) {
        const birthday = new Date(this.user.birthday);
        const age = differenceInYears(new Date(), birthday);
        if (age >= 18) {
          this.form.get('underAge').setValue(true);
          this.showUnderAgeFormControl = false;
        }
      } else {
        this.form.get('underAge').setValue(true);
        this.showUnderAgeFormControl = false;
      }
    }, 0);
  }

  close() {
    this.#modalCtrl.dismiss();
  }

  acceptTerms() {
    if (this.mode === 'register') {
      this.#registerSamlUser();
    } else {
      this.#updateTermsAndPrivacy();
    }
  }

  #registerSamlUser() {
    this.#loadingService.present();
    this.#apiService
      .post('saml2/register', {
        ...this.user,
        campus_registered: this.form.get('campus').value,
        type: this.user.role,
        terms: true,
      })
      .pipe(
        take(1),
        catchError(() => {
          this.#notificationService.presentToast('Error al registrar usuario SAML. Favor de intentar nuevamente.');
          return of(null);
        })
      )
      .subscribe((response) => {
        if (response?.api_token && response?.user) {
          this.#modalCtrl.dismiss(response);
        }
        this.#loadingService.dismiss();
      });
  }

  #updateTermsAndPrivacy() {
    this.#loadingService.present();
    this.#apiService
      .put('profile/terms_privacy', {
        terms: 1,
        privacy: 1,
      })
      .pipe(
        take(1),
        catchError(() => {
          this.#notificationService.presentToast('Favor de intentar nuevamente.');
          return of(null);
        })
      )
      .subscribe((response) => {
        console.log({ response });
        if (response) {
          this.#modalCtrl.dismiss(response);
        }
        this.#loadingService.dismiss();
      });
  }

  #getCampus() {
    return this.#apiService.get<ApiResponse<Campus[]>>('campus').pipe(
      map((res) => res.data),
      catchError(() => of([]))
    );
  }

  #getSupplier(campusId: number): Observable<Supplier> {
    return this.#apiService.get<Supplier>(`supplier?campus_id=${campusId}`).pipe(catchError(() => of(null)));
  }
}
