import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { DataResolverService } from './resolver/data-resolver.service';
import { AuthGuard } from './services/auth.guard';
import { BusinessGuard } from './services/business.guard';
import { ExpresotecGuard } from './services/expresotec.guard';
import { GuardiaGuard } from './services/guardia.guard';

const routes: Routes = [
  { path: 'initial', loadChildren: () => import('./initial/initial.module').then((m) => m.InitialPageModule) },
  { path: 'login', loadChildren: () => import('./login/login.module').then((m) => m.LoginPageModule) },
  {
    path: 'tecservice',
    loadChildren: () => import('./tabs/tabs.module').then((m) => m.TabsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'tecservice-business',
    loadChildren: () => import('./tabs-business/tabs-business.module').then((m) => m.TabsBusinessPageModule),
    canActivate: [BusinessGuard],
  },
  {
    path: 'tecservice-expresotec',
    loadChildren: () => import('./tabs-expresotec/tabs-expresotec.module').then((m) => m.TabsExpresotecPageModule),
    canActivate: [ExpresotecGuard],
  },
  {
    path: 'tecservice-transportec',
    loadChildren: () => import('./tabs-transportec/tabs-transportec.module').then((m) => m.TabsTransportecPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'tecservice-driver',
    loadChildren: () => import('./tabs-driver/tabs-driver.module').then((m) => m.TabsDriverPageModule),
    canActivate: [AuthGuard],
  },
  { path: '', redirectTo: '/initial', pathMatch: 'full' },
  { path: 'id', loadChildren: () => import('./id/id.module').then((m) => m.IdPageModule) },
  {
    path: 'preregister',
    loadChildren: () => import('./preregister/preregister.module').then((m) => m.PreregisterPageModule),
  },
  {
    path: 'register',
    children: [
      {
        path: '',
        loadChildren: () => import('./register/register.module').then((m) => m.RegisterPageModule),
      },
      {
        path: ':id',
        children: [
          {
            path: '',
            resolve: {
              special: DataResolverService,
            },
            loadChildren: () => import('./register/register.module').then((m) => m.RegisterPageModule),
          },
        ],
      },
    ],
  },
  { path: 'route', loadChildren: () => import('./expresotec/route/route.module').then((m) => m.RoutePageModule) },
  {
    path: 'route-map',
    loadChildren: () => import('./expresotec/route-map/route-map.module').then((m) => m.RouteMapPageModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then((m) => m.ForgotPasswordPageModule),
  },
  {
    path: 'email-confirmation',
    loadChildren: () =>
      import('./email-confirmation/email-confirmation.module').then((m) => m.EmailConfirmationPageModule),
  },
  {
    path: 'points-history',
    loadChildren: () => import('./profile/points-history/points-history.module').then((m) => m.PointsHistoryPageModule),
  },
  { path: 'scan', loadChildren: () => import('./scan/scan.module').then((m) => m.ScanPageModule) },
  {
    path: 'payment-card',
    loadChildren: () =>
      import('./tabs-transportec/wallet/payment-card/payment-card.module').then((m) => m.PaymentCardPageModule),
  },
  {
    path: 'zones',
    loadChildren: () => import('./tabs-transportec/home/zones/zones.module').then((m) => m.ZonesPageModule),
  },
  {
    path: 'subscribe',
    loadChildren: () => import('./tabs-transportec/subscribe/subscribe.module').then((m) => m.SubscribePageModule),
  },
  { path: 'match', loadChildren: () => import('./locatec-user/match/match.module').then((m) => m.MatchPageModule) },
  {
    path: 'not-found',
    loadChildren: () => import('./locatec-user/not-found/not-found.module').then((m) => m.NotFoundPageModule),
  },
  {
    path: 'locatec',
    loadChildren: () => import('./locatec-employee/locatec/locatec.module').then((m) => m.LocatecPageModule),
  },
  {
    path: 'locatec',
    canActivate: [GuardiaGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./locatec-employee/locatec/locatec.module').then((m) => m.LocatecPageModule),
      },
      {
        path: 'article-one',
        children: [
          {
            path: '',
            resolve: {
              special: DataResolverService,
            },
            loadChildren: () =>
              import('./locatec-employee/article-creation/article-creation.module').then(
                (m) => m.ArticleCreationPageModule
              ),
          },
        ],
      },
      {
        path: 'article-summary',
        children: [
          {
            path: '',
            resolve: {
              special: DataResolverService,
            },
            loadChildren: () =>
              import('./locatec-employee/article-summary/article-summary.module').then(
                (m) => m.ArticleSummaryPageModule
              ),
          },
        ],
      },
      {
        path: 'article-created',
        children: [
          {
            path: '',
            resolve: {
              special: DataResolverService,
            },
            loadChildren: () =>
              import('./locatec-employee/article-created/article-created.module').then(
                (m) => m.ArticleCreatedPageModule
              ),
          },
        ],
      },
      {
        path: 'article-return',
        children: [
          {
            path: '',
            resolve: {
              special: DataResolverService,
            },
            loadChildren: () =>
              import('./locatec-employee/article-return-home/article-return-home.module').then(
                (m) => m.ArticleReturnHomePageModule
              ),
          },
          {
            path: 'scan',
            resolve: {
              special: DataResolverService,
            },
            loadChildren: () =>
              import('./locatec-employee/article-scan/article-scan.module').then((m) => m.ArticleScanPageModule),
          },
          {
            path: 'sign',
            resolve: {
              special: DataResolverService,
            },
            loadChildren: () =>
              import('./locatec-employee/article-return-signature/article-return-signature.module').then(
                (m) => m.ArticleReturnSignaturePageModule
              ),
          },
          {
            path: 'summary',
            resolve: {
              special: DataResolverService,
            },
            loadChildren: () =>
              import('./locatec-employee/article-return-summary/article-return-summary.module').then(
                (m) => m.ArticleReturnSummaryPageModule
              ),
          },
          {
            path: ':id',
            children: [
              {
                path: '',
                resolve: {
                  special: DataResolverService,
                },
                loadChildren: () =>
                  import('./locatec-employee/article-return-one/article-return-one.module').then(
                    (m) => m.ArticleReturnOnePageModule
                  ),
              },
            ],
          },
        ],
      },
      {
        path: 'article-catalog',
        children: [
          {
            path: '',
            resolve: {
              special: DataResolverService,
            },
            loadChildren: () =>
              import('./locatec-employee/article-catalog/article-catalog.module').then(
                (m) => m.ArticleCatalogPageModule
              ),
          },
          {
            path: 'scan',
            resolve: {
              special: DataResolverService,
            },
            loadChildren: () =>
              import('./locatec-employee/article-return-home/article-return-home.module').then(
                (m) => m.ArticleReturnHomePageModule
              ),
          },
          {
            path: 'sign',
            resolve: {
              special: DataResolverService,
            },
            loadChildren: () =>
              import('./locatec-employee/article-return-signature/article-return-signature.module').then(
                (m) => m.ArticleReturnSignaturePageModule
              ),
          },
          {
            path: ':id',
            children: [
              {
                path: '',
                resolve: {
                  special: DataResolverService,
                },
                loadChildren: () =>
                  import('./locatec-employee/article-return-one/article-return-one.module').then(
                    (m) => m.ArticleReturnOnePageModule
                  ),
              },
            ],
          },
        ],
      },
      {
        path: 'report',
        children: [
          {
            path: '',
            resolve: {
              special: DataResolverService,
            },
            loadChildren: () =>
              import('./locatec-user/report-one/report-one.module').then((m) => m.ReportOnePageModule),
          },
          {
            path: 'two',
            resolve: {
              special: DataResolverService,
            },
            loadChildren: () =>
              import('./locatec-user/report-two/report-two.module').then((m) => m.ReportTwoPageModule),
          },
          {
            path: 'three',
            resolve: {
              special: DataResolverService,
            },
            loadChildren: () =>
              import('./locatec-user/report-three/report-three.module').then((m) => m.ReportThreePageModule),
          },
          {
            path: 'summary',
            resolve: {
              special: DataResolverService,
            },
            loadChildren: () =>
              import('./locatec-user/report-summary/report-summary.module').then((m) => m.ReportSummaryPageModule),
          },
        ],
      },
    ],
  },
  {
    path: 'article-report',
    loadChildren: () =>
      import('./locatec-employee/article-report/article-report.module').then((m) => m.ArticleReportPageModule),
  },
  {
    path: 'article-report-id',
    loadChildren: () =>
      import('./locatec-employee/article-report-id/article-report-id.module').then((m) => m.ArticleReportIdPageModule),
  },
  {
    path: 'article-catalog',
    loadChildren: () =>
      import('./locatec-employee/article-catalog/article-catalog.module').then((m) => m.ArticleCatalogPageModule),
  },
  {
    path: 'article-return-summary',
    loadChildren: () =>
      import('./locatec-employee/article-return-summary/article-return-summary.module').then(
        (m) => m.ArticleReturnSummaryPageModule
      ),
  },
  {
    path: '**',
    redirectTo: '/initial',
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
