/* eslint-disable @typescript-eslint/no-explicit-any */
import { Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { QueryConfig } from '@shared/models';

const API_BASE_URL = environment.apiBaseUrl;

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  get<T>(url: string, config?: QueryConfig): Observable<T> {
    let params: HttpParams = new HttpParams();

    Object.keys(config || {}).forEach((key) => {
      params = params.set(key, JSON.stringify(config[key]));
    });

    return this.http.get<T>(`${API_BASE_URL}${url}`, { params });
  }

  post<T>(url: string, data: Partial<T>): Observable<any> {
    return this.http.post<T>(`${API_BASE_URL}${url}`, data);
  }

  put<T>(url: string, data: Partial<T>): Observable<any> {
    return this.http.put<T>(`${API_BASE_URL}${url}`, data);
  }

  delete<T>(url: string): Observable<unknown> {
    return this.http.delete<T>(`${API_BASE_URL}${url}`);
  }
}
