<ng-container *ngIf="{ supplier: supplier$ | async, campus: campus$ | async } as vm">
  <ion-header class="ion-no-border bg">
    <ion-toolbar>
      <ion-buttons slot="start" *ngIf="mode !== 'update'">
        <ion-button (click)="close()">
          <ion-icon slot="icon-only" name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
      <ion-title>Términos y condiciones</ion-title>
    </ion-toolbar>
  </ion-header>

  <ion-content class="ion-no-border bg">
    <form [formGroup]="form">
      <ion-item lines="full" *ngIf="showUnderAgeFormControl">
        <ion-label>
          Por ser menor de edad, es necesario que un padre o un tutor esté presente y autorice el registro. Al aceptar,
          se entiende que fue en presencia del padre o tutor del alumno a registrarse.
        </ion-label>
        <ion-checkbox slot="end" formControlName="underAge"></ion-checkbox>
      </ion-item>
      <ion-item *ngIf="user?.role === 'Colaborador' && !user?.campus_registered" lines="full">
        <ion-label position="stacked">Campus</ion-label>
        <ion-select placeholder="Seleccionar" formControlName="campus">
          <ion-select-option [value]="item.id" *ngFor="let item of vm.campus">{{ item.name }}</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item lines="full">
        <ion-label position="stacked">Términos y condiciones</ion-label>
        <div [innerHTML]="vm.supplier?.terms" class="ion-padding"></div>
      </ion-item>
      <ion-item lines="full">
        <ion-label position="stacked">Aviso de privacidad</ion-label>
        <div [innerHTML]="vm.supplier?.privacy" class="ion-padding"></div>
      </ion-item>
    </form>
  </ion-content>
  <ion-footer>
    <ion-toolbar>
      <ion-button expand="block" (click)="acceptTerms()" [disabled]="form.invalid" *ngIf="mode !== 'read'">
        Aceptar términos y condiciones
      </ion-button>
      <ion-button expand="block" (click)="close()" *ngIf="mode === 'read'">Cerrar</ion-button>
    </ion-toolbar>
  </ion-footer>
</ng-container>
