import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import { AlertButton } from '@ionic/core';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private toastCtrl: ToastController, private alertCtrl: AlertController) {}

  async presentToast(message: string, duration: number = 3000, position: 'top' | 'bottom' | 'middle' = 'top') {
    const toast = await this.toastCtrl.create({ message, duration, position });
    toast.present();
  }

  async presentAlert(message: string, header: string = 'Alert', buttons: (AlertButton | string)[] = ['OK']) {
    const alert = await this.alertCtrl.create({ message, header, buttons });
    alert.present();
  }
}
