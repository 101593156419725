import { AfterViewInit, Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

const DIMISS_ON_SUCCESS_TIMEOUT = 2000;
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements AfterViewInit {
  @Input() has_toolbar: boolean = false;
  @Input() title: string;
  @Input() bg_class: string;
  @Input() content: string;

  constructor(private modalCtrl: ModalController) {}

  ngAfterViewInit(): void {
    if (this.bg_class?.startsWith('success')) {
      setTimeout(() => {
        this.close();
      }, DIMISS_ON_SUCCESS_TIMEOUT);
    }
  }

  async close() {
    this.modalCtrl.dismiss();
  }
}
