import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ImageViewerComponent } from './image-viewer.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        component: ImageViewerComponent,
      },
    ]),
  ],
  declarations: [ImageViewerComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ImageViewerModule {}
