import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SaleComponent } from './sale.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        component: SaleComponent,
      },
    ]),
  ],
  declarations: [SaleComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SaleModule {}
