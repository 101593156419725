import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { ScanningModalComponent } from '@shared/components/scanning-modal/scanning-modal.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ImageViewerModule } from './component/image-viewer/image-viewer/image-viewer.module';
import { ModalModule } from './component/modal/modal.module';
import { PaymentConfirmationComponent } from './component/payment-confirmation/payment-confirmation.component';
import { ReviewModule } from './component/review/review.module';
import { SaleModule } from './component/sale/sale.module';
import { TutorialComponent } from './component/tutorial/tutorial.component';
import { IdPageModule } from './id/id.module';
import { AuthInterceptorService } from './interceptors/auth-interceptor.service';
import { ErrorInterceptorService } from './interceptors/error-interceptor.service';
import { RegisterSamlModalComponent } from './login/components/register-saml-modal/register-saml-modal.component';
import { PipesModule } from './pipes/pipes.module';

@NgModule({
  declarations: [
    AppComponent,
    TutorialComponent,
    PaymentConfirmationComponent,
    RegisterSamlModalComponent,
    ScanningModalComponent,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    IdPageModule,
    HttpClientModule,
    ImageViewerModule,
    ReviewModule,
    ModalModule,
    SaleModule,
    BrowserAnimationsModule,
    PipesModule,
    ReactiveFormsModule,
  ],
  providers: [
    Geolocation,
    InAppBrowser,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptorService, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
