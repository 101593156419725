import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ModalComponent } from './modal.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        component: ModalComponent,
      },
    ]),
  ],
  declarations: [ModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ModalModule {}
