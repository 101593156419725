import { ApiService } from 'src/app/services/api.service';
import { LoadingService } from 'src/app/services/loading.service';
import { NotificationService } from 'src/app/services/notification/notification.service';

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, NavController } from '@ionic/angular';

export interface SurveyCategory {
  name: string;
  id: number;
}

export interface SurveyCategoryResponse {
  data: SurveyCategory[];
}

export interface Score {
  name: string;
  score: number;
  category_id: number;
}

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
})
export class ReviewComponent implements OnInit {
  @Input() id: number;
  @Input() answer1 = 0;
  @Input() comments = '';

  @Input() businessId: number;
  @Input() busId: number;
  @Input() busticketId: number;
  @Input() paymentId: number;
  @Input() earningId: number;
  @Input() saleId: number;
  @Input() endpoint = 'surveys';
  @ViewChild('commentsTextArea', { static: false }) commentsTextArea: ElementRef;
  scores: Score[] = [];

  constructor(
    private apiService: ApiService,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private navCtrl: NavController,
    private router: Router,
    private loadingService: LoadingService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.loadCategories();
    if (this.busticketId) {
      this.endpoint = 'transportecrating';
    } else if (this.busId) {
      this.endpoint = 'expresorating';
    } else if (this.saleId) {
      this.endpoint = 'mealplanrating';
    }
  }

  onRateChange(event) {
    this.answer1 = event;
    setTimeout(() => {
      this.commentsTextArea.nativeElement.focus();
    }, 150); // a least 150ms.
  }

  updateComments(event) {
    // console.log(event);
    this.comments = event.target.value;
  }

  sendToServer() {
    if (this.comments) {
      this.loadingService.present();
      if (this.id) {
        // Update review
        this.apiService
          .putData('' + this.endpoint + '/' + this.id, {
            busticket_id: this.busticketId,
            sale_id: this.saleId,
            business_id: this.businessId,
            payment_id: this.paymentId,
            bus_id: this.busId,
            earning_id: this.earningId,
            answer1: this.answer1,
            comments: this.comments,
            scores: this.scores,
          })
          .then(
            async (res) => {
              console.log(res);
              // alert('Res: ' + JSON.stringify(res));
              if (res.success) {
                const alert = await this.alertCtrl.create({
                  header: 'Calificación modificada',
                  message: 'Muchas gracias por su tiempo. Su opinión es muy importante para nosotros.',
                  buttons: [
                    {
                      text: 'OK',
                      role: 'cancel',
                      handler: () => {
                        console.log('Cancel clicked');
                        this.modalCtrl.dismiss();
                      },
                    },
                  ],
                });
                await alert.present();
              }
            },
            (err) => {
              console.log(JSON.stringify(err));
            }
          )
          .finally(() => {
            this.loadingService.dismiss();
          });
      } else {
        // Nuevo review
        this.apiService
          .postData('' + this.endpoint, {
            busticket_id: this.busticketId,
            sale_id: this.saleId,
            business_id: this.businessId,
            payment_id: this.paymentId,
            bus_id: this.busId,
            earning_id: this.earningId,
            answer1: this.answer1,
            comments: this.comments,
            scores: this.scores,
          })
          .then(
            async (res) => {
              console.log(res);
              // alert('Res: ' + JSON.stringify(res));
              if (res.success) {
                const alert = await this.alertCtrl.create({
                  header: 'Calificación enviada',
                  message: 'Muchas gracias por su tiempo. Su opinión es muy importante para nosotros.',
                  buttons: [
                    {
                      text: 'OK',
                      role: 'cancel',
                      handler: () => {
                        console.log('Cancel clicked');
                        this.modalCtrl.dismiss();
                        if (this.router.url.indexOf('transportec') >= 0) {
                          // this.navCtrl.navigateRoot('/tecservice-transportec/tickets/ticket/' + res.ticket.id);
                        } else {
                          this.navCtrl.navigateRoot('/tecservice/home/tickets/' + res.ticket.id);
                        }
                      },
                    },
                  ],
                });
                await alert.present();
              }
            },
            (err) => {
              console.log(JSON.stringify(err));
            }
          )
          .finally(() => {
            this.loadingService.dismiss();
          });
      }
    } else {
      this.notificationService.presentToast('Comentario obligatorio.');
    }
  }

  loadCategories() {
    this.loadingService.present();
    this.apiService
      .getData(`survey_categories`)
      .then((response: SurveyCategoryResponse | unknown) => {
        this.loadingService.dismiss();
        const res = response as SurveyCategoryResponse;
        res.data.forEach((category: SurveyCategory) => {
          this.scores.push({
            name: category.name,
            category_id: category.id,
            score: 1,
          });
        });
        console.log(this.scores);
      })
      .catch(() => {
        this.loadingService.dismiss();
      });
  }

  setScore(value: number, item: number) {
    this.scores[item].score = value;
  }

  async close() {
    this.modalCtrl.dismiss();
  }

  goHelp() {
    this.modalCtrl.dismiss('help');
  }
}
