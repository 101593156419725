// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';

import { Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { SplashScreen } from '@capacitor/splash-screen';
import {
  ActionSheetController,
  IonRouterOutlet,
  ModalController,
  Platform,
  PopoverController,
  ToastController,
} from '@ionic/angular';

import { AuthService } from './services/auth.service';
import { EventService } from './services/event.service';
import { LoadingService } from './services/loading.service';

// register Swiper custom elements
register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  swipe = true;

  // set up hardware back button event.
  lastTimeBackPress = 0;
  timePeriodToExit = 2000;
  loading: HTMLIonLoadingElement;

  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
  @ViewChild(IonRouterOutlet) routerOutlet: IonRouterOutlet;

  constructor(
    private platform: Platform,
    private auth: AuthService,
    private modalCtrl: ModalController,
    private actionSheetCtrl: ActionSheetController,
    private popoverCtrl: PopoverController,
    private router: Router,
    private toastController: ToastController,
    private eventService: EventService,
    private loadingService: LoadingService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      SplashScreen.hide();

      this.eventService.watch.subscribe((value) => {
        if (value === 'user:logout') {
          this.auth.logout();
        }
        if (value === 'loading:present') {
          this.loadingService.present();
        }
        if (value === 'loading:dismiss') {
          this.loadingService.dismiss();
        }
        if (value === 'back:enable') {
          this.backButtonEvent2();
        }
      });
    });
  }

  backButtonEvent() {
    this.platform.backButton.subscribeWithPriority(0, () => {
      this.routerOutlets.forEach((outlet: IonRouterOutlet) => {
        /*if (this.router.url != '/tabs/tab1') {
          await this.router.navigate(['/tabs/tab1']);
        } else*/
        alert(this.router.url);
        if (this.router.url === '/tecservice/home') {
          this.auth.askExit();
        }
      });
    });
  }

  // active hardware back button
  backButtonEvent2() {
    this.platform.backButton.subscribeWithPriority(0, async () => {
      // close action sheet
      try {
        const element = await this.actionSheetCtrl.getTop();
        if (element) {
          element.dismiss();
          return;
        }
      } catch (error) {}

      // close popover
      try {
        const element = await this.popoverCtrl.getTop();
        if (element) {
          element.dismiss();
          return;
        }
      } catch (error) {}

      // close modal
      try {
        const element = await this.modalCtrl.getTop();
        if (element) {
          element.dismiss();
          return;
        }
      } catch (error) {
        console.log(error);
      }

      this.routerOutlets.forEach(async (outlet: IonRouterOutlet) => {
        if (outlet && outlet.canGoBack()) {
          outlet.pop();
        } else {
          if (new Date().getTime() - this.lastTimeBackPress < this.timePeriodToExit) {
            // navigator['app'].exitApp(); // work for ionic 4
          } else {
            const toast3 = await this.toastController.create({
              message: 'Pulse atrás nuevamente para salir de la App.',
              duration: 2000,
              position: 'middle',
            });
            toast3.present();
            this.lastTimeBackPress = new Date().getTime();
          }
        }
      });
    });
  }
}
