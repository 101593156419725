<ion-content class="ion-padding">
  <div id="id" *ngIf="user">
    <img src="/assets/icon/tecservice.svg" class="bg-logo" />
    <ion-button (click)="close()" class="close" fill="clear" color="light">
      <ion-icon slot="icon-only" name="close"></ion-icon>
    </ion-button>
    <div class="id-header">
      <ion-avatar slot="start">
        <img [src]="user.avatar" *ngIf="user.avatar" />
        <img src="/assets/imgs/logo-blanco.png" *ngIf="!user.avatar" />
      </ion-avatar>
      <p>{{user?.user_type?.name || user?.type}}</p>
    </div>
    <div class="id-content">
      <div class="item">
        <ion-label>Nombre</ion-label>
        <p>{{user.name}} {{user.lastname}}</p>
      </div>

      <div class="item" *ngIf="user.enrollment">
        <ion-label *ngIf="(user.enrollment).includes('a') || (user.enrollment).includes('A')">Matrícula</ion-label>
        <ion-label *ngIf="(user.enrollment).includes('l') || (user.enrollment).includes('L')">Nómina</ion-label>
        <p>{{user.enrollment | uppercase}}</p>
      </div>

      <div class="item">
        <ion-label>Campus</ion-label>
        <p>{{user.campus_registered_name}}</p>
      </div>
      <div class="item" *ngIf="user.enrollment">
        <img
          [src]="'http://bwipjs-api.metafloor.com/?bcid=code128&scale=3&includetext&text='+user.enrollment"
          class="barcode"
        />
      </div>
    </div>
  </div>
</ion-content>
