import { InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';

export function groupBy<T>(
  array: T[],
  predicate: (value: T, index: number, array: T[]) => string
): { [key: string]: T[] } {
  return array.reduce((acc, value, index, array) => {
    (acc[predicate(value, index, array)] = acc[predicate(value, index, array)] || []).push(value);
    return acc;
  }, {} as { [key: string]: T[] });
}

export function dayOfTheWeek(index: number | string): string {
  switch (+index) {
    case 0:
      return 'Domingo';
    case 1:
      return 'Lunes';
    case 2:
      return 'Martes';
    case 3:
      return 'Miércoles';
    case 4:
      return 'Jueves';
    case 5:
      return 'Viernes';
    case 6:
      return 'Sábado';
    default:
      return '';
  }
}

export function getMonthName(value: string | number): string {
  switch (+value) {
    case 1:
      return 'Enero';
    case 2:
      return 'Febrero';
    case 3:
      return 'Marzo';
    case 4:
      return 'Abril';
    case 5:
      return 'Mayo';
    case 6:
      return 'Junio';
    case 7:
      return 'Julio';
    case 8:
      return 'Agosto';
    case 9:
      return 'Septiembre';
    case 10:
      return 'Octubre';
    case 11:
      return 'Noviembre';
    case 12:
      return 'Diciembre';
    default:
      return '';
  }
}

export function getSuscriptionTypeName(name: string): string {
  switch (name) {
    case 'SR':
      return 'Redondo';
    case 'SRF':
      return 'Redondo Flexible';
    case 'SS':
      return 'Sencillo Fijo';
    case 'SSF':
      return 'Sencillo Flexible';
    case 'PV':
      return 'Pago por Viaje';
    default:
      return '';
  }
}

export const iabOptions: InAppBrowserOptions = {
  location: 'no',
  hidenavigationbuttons: 'yes',
  toolbarposition: 'bottom',
  toolbarcolor: '#0e53a1',
  navigationbuttoncolor: '#ffffff',
  hideurlbar: 'yes',
  closebuttoncaption: 'Cerrar',
};

export const convertBlobToBase64 = (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.readAsDataURL(blob);
  });

export const getMappedPaymentMethod = (method: string): string => {
  switch (method) {
    case 'oxxo':
      return 'Oxxo';
    case 'paypal':
      return 'PayPal';
    case 'credit_card':
      return 'Tarjeta';
    case 'manual':
      return 'Manual';
    case 'spei':
      return 'SPEI';
    case 'wallet':
      return 'Wallet';
    default:
      return method.charAt(0).toUpperCase() + method.slice(1);
  }
};

export const getParsedValue = <T>(value: string): T | null => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return value as unknown as T;
  }
};

export const isBase64 = (text: string): boolean => {
  const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  return base64regex.test(text);
};
